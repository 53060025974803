import { formatISO, parseISO, add, differenceInDays } from "date-fns";
import redisClient from "../utils/redis.server";
import crypto from 'crypto';
import { log } from "~/helpers/log";



export const tripBuilderInflate = (tripBuilderCookieValue) => {
  let inflated = []
  let tripObject1 = {}
  let tripObject2 = {}
  //console.log("IN", tripBuilderCookieValue)


  if (tripBuilderCookieValue && tripBuilderCookieValue.length) {
    if (typeof tripBuilderCookieValue[0].w !== "undefined") {
      1
      tripObject1 = {
        "originFlight": {
          "date": tripBuilderCookieValue[0].o.date,
          "timestamp": tripBuilderCookieValue[0].o.t,
          "origin": tripBuilderCookieValue[0].o.o,
          "destination": tripBuilderCookieValue[0].o.d,
          "price": tripBuilderCookieValue[0].o.p,
          "duration": tripBuilderCookieValue[0].o.du,
          "departureTime": tripBuilderCookieValue[0].o.dt,
          "arrivalTime": tripBuilderCookieValue[0].o.at,
          "meta": tripBuilderCookieValue[0].o.m
        },
        "destinationFlight": {
          "date": tripBuilderCookieValue[0].d.date,
          "timestamp": tripBuilderCookieValue[0].d.t,
          "origin": tripBuilderCookieValue[0].d.o,
          "destination": tripBuilderCookieValue[0].d.d,
          "price": tripBuilderCookieValue[0].d.p,
          "duration": tripBuilderCookieValue[0].d.du,
          "departureTime": tripBuilderCookieValue[0].d.dt,
          "arrivalTime": tripBuilderCookieValue[0].d.at,
          "meta": tripBuilderCookieValue[0].d.m
        },

        "middleFlight1": {
          "date": tripBuilderCookieValue[0].m1.date,
          "timestamp": tripBuilderCookieValue[0].m1.t,
          "origin": tripBuilderCookieValue[0].m1.o,
          "destination": tripBuilderCookieValue[0].m1.d,
          "price": tripBuilderCookieValue[0].m1.p,
          "duration": tripBuilderCookieValue[0].m1.du,
          "departureTime": tripBuilderCookieValue[0].m1.dt,
          "arrivalTime": tripBuilderCookieValue[0].m1.at,
          "meta": tripBuilderCookieValue[0].m1.m
        },

        "middleFlight2": {
          "date": tripBuilderCookieValue[0].m2.date,
          "timestamp": tripBuilderCookieValue[0].m2.t,
          "origin": tripBuilderCookieValue[0].m2.o,
          "destination": tripBuilderCookieValue[0].m2.d,
          "price": tripBuilderCookieValue[0].m2.p,
          "duration": tripBuilderCookieValue[0].m2.du,
          "departureTime": tripBuilderCookieValue[0].m2.dt,
          "arrivalTime": tripBuilderCookieValue[0].m2.at,
          "meta": tripBuilderCookieValue[0].m2.m
        },

        "waypoint": tripBuilderCookieValue[0].w

      }

      if (tripBuilderCookieValue.length > 1) {
        tripObject2 = {
          "originFlight": {
            "date": tripBuilderCookieValue[1].o.date,
            "timestamp": tripBuilderCookieValue[1].o.t,
            "origin": tripBuilderCookieValue[1].o.o,
            "destination": tripBuilderCookieValue[1].o.d,
            "price": tripBuilderCookieValue[1].o.p,
            "duration": tripBuilderCookieValue[1].o.du,
            "departureTime": tripBuilderCookieValue[1].o.dt,
            "arrivalTime": tripBuilderCookieValue[1].o.at
          },
          "destinationFlight": {
            "date": tripBuilderCookieValue[1].d.date,
            "timestamp": tripBuilderCookieValue[1].d.t,
            "origin": tripBuilderCookieValue[1].d.o,
            "destination": tripBuilderCookieValue[1].d.d,
            "price": tripBuilderCookieValue[1].d.p,
            "duration": tripBuilderCookieValue[1].d.du,
            "departureTime": tripBuilderCookieValue[1].d.dt,
            "arrivalTime": tripBuilderCookieValue[1].d.at
          },
          "middleFlight1": {
            "date": tripBuilderCookieValue[1].m1.date,
            "timestamp": tripBuilderCookieValue[1].m1.t,
            "origin": tripBuilderCookieValue[1].m1.o,
            "destination": tripBuilderCookieValue[1].m1.d,
            "price": tripBuilderCookieValue[1].m1.p,
            "duration": tripBuilderCookieValue[1].m1.du,
            "departureTime": tripBuilderCookieValue[1].m1.dt,
            "arrivalTime": tripBuilderCookieValue[1].m1.at
          },
          "middleFlight2": {
            "date": tripBuilderCookieValue[1].m2.date,
            "timestamp": tripBuilderCookieValue[1].m2.t,
            "origin": tripBuilderCookieValue[1].m2.o,
            "destination": tripBuilderCookieValue[1].m2.d,
            "price": tripBuilderCookieValue[1].m2.p,
            "duration": tripBuilderCookieValue[1].m2.du,
            "departureTime": tripBuilderCookieValue[1].m2.dt,
            "arrivalTime": tripBuilderCookieValue[1].m2.at
          },
          "waypoint": tripBuilderCookieValue[1].w
        }


      }
    }


  }

  inflated = [tripObject1, tripObject2];
  //log("OUT", inflated)

  return inflated
}


export const tripBuilderLoader = async (cookies) => {

  if (typeof cookies === 'undefined' || cookies === null) {
    return []
  }
  let cookiesArray = cookies.split(';');
  let tripBuilderCookieValue = null;

  // Loop through all cookies to find the trip_builder cookie
  cookiesArray.forEach(cookie => {

    const [name, value] = cookie.trim().split('=');

  });

  // REINFLATE


  if (tripBuilderCookieValue === null) {
    return []
  }

  tripBuilderCookieValue = JSON.parse(tripBuilderCookieValue)


  if (typeof tripBuilderCookieValue[0].w !== "undefined") {
    return tripBuilderInflate(tripBuilderCookieValue)
  };
}



export const tripBuilderGet = () => {
  const cookies = document.cookie.split(';');

  let tripBuilderCookieValue = null;

  // Loop through all cookies to find the trip_builder cookie
  cookies.forEach(cookie => {

    const [name, value] = cookie.trim().split('=');

    if (name === 'trip_builder') {

      tripBuilderCookieValue = decodeURIComponent(value);
    }
  });

  return tripBuilderCookieValue;
};



export const tripBuilderGetAndInflateWithoutCookie = (document) => {
  const cookies = document.cookie.split(';');

  let tripBuilderCookieValue = null;

  // Loop through all cookies to find the trip_builder cookie
  cookies.forEach(cookie => {

    const [name, value] = cookie.trim().split('=');

    if (name === 'trip_builder') {
      tripBuilderCookieValue = JSON.parse(value)

      return tripBuilderInflate(tripBuilderCookieValue)
    }
  });


  return tripBuilderCookieValue;
};


export const tripBuilderGetAndInflate = () => {
  const cookies = document.cookie.split(';');

  let tripBuilderCookieValue = null;

  // Loop through all cookies to find the trip_builder cookie
  cookies.forEach(cookie => {

    const [name, value] = cookie.trim().split('=');

    if (name === 'trip_builder') {
      tripBuilderCookieValue = JSON.parse(value)

      return tripBuilderInflate(tripBuilderCookieValue)
    }
  });


  return tripBuilderCookieValue;
};


export const stateLoader = async (cookies) => {
  if (cookies === null) {
    return []
  }
  let cookiesArray = cookies.split(';');
  let stateCookieValue = {};

  // Loop through all cookies to find the state cookie
  cookiesArray.forEach(cookie => {

    const [name, value] = cookie.trim().split('=');

    if (name === 'state') {
      stateCookieValue = value;
    }
  });

  return stateCookieValue;
};



export const stateGet = () => {
  const cookies = document.cookie.split(';');

  let stateCookieValue = null;

  // Loop through all cookies to find the state cookie
  cookies.forEach(cookie => {

    const [name, value] = cookie.trim().split('=');

    if (name === 'state') {

      stateCookieValue = decodeURIComponent(value);
    }
  });

  return stateCookieValue;
};


export const stateSet = (data) => {
  // Set the updated trip builder data as the value of the cookie
  document.cookie = `state=${JSON.stringify(data)}`;
};






export const tripBuilderSet = (data) => {
  // Get existing trip builder data from cookie
  const existingTripBuilder = tripBuilderGet("trip_builder");

  let updatedData;
  if (existingTripBuilder) {
    // Parse existing trip builder data if it exists
    let parsedData = JSON.parse(existingTripBuilder);
    let exists = false;


    if (
      typeof parsedData[0] !== "undefined" &&
      typeof parsedData[0].w !== "undefined" &&
      parsedData[0].w.route === data.w.route &&
      JSON.stringify(parsedData[0].o) === JSON.stringify(data.o) &&
      JSON.stringify(parsedData[0].d) === JSON.stringify(data.d)
    ) {
      parsedData[0] = data
      exists = true
    }

    if (
      typeof parsedData[0] !== "undefined" &&
      typeof parsedData[0].waypoint !== "undefined" &&
      parsedData[0].waypoint.route === data.w.route &&
      JSON.stringify(parsedData[0].origin) === JSON.stringify(data.o) &&
      JSON.stringify(parsedData[0].destination) === JSON.stringify(data.d)
    ) {
      parsedData[0] = data
      exists = true
    }



    if (
      typeof parsedData[1] !== "undefined" &&
      typeof parsedData[1].w !== "undefined" &&
      parsedData[1].w.route === data.w.route &&
      JSON.stringify(parsedData[1].o) === JSON.stringify(data.o) &&
      JSON.stringify(parsedData[1].d) === JSON.stringify(data.d)
    ) {
      parsedData[1] = data
      exists = true
    }


    if (
      typeof parsedData[1] !== "undefined" &&
      typeof parsedData[1].waypoint !== "undefined" &&
      parsedData[1].waypoint.route === data.w.route &&
      JSON.stringify(parsedData[1].oestination) === JSON.stringify(data.o) &&
      JSON.stringify(parsedData[1].destination) === JSON.stringify(data.d)
    ) {
      parsedData[1] = data
      exists = true
    }

    if (!exists) {

      if (parsedData.length > 1) {
        parsedData.pop();
      }
      parsedData.push(data);
    }
    else {
      //console.log("TRIP ALREADY EXISTS! SKIP!", exists)
    }

    // Stringify the updated data
    updatedData = JSON.stringify(parsedData);
  } else {
    // If trip builder data doesn't exist, create a new array with the data
    updatedData = JSON.stringify([data]);
  }

  // Set the updated trip builder data as the value of the cookie
  document.cookie = `trip_builder=${updatedData}`;
};


export const tripBuilderDelete = () => {
  document.cookie = "trip_builder=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};


export const filterOutLoops = async (flights) => {
  return flights.filter((flight) => {
    const route = flight.waypoint.route;
    const airportCodes = route.split('-');
    const uniqueAirportCodes = new Set(airportCodes);

    return airportCodes.length === uniqueAirportCodes.size;
  });
}


export const getWaypoints = async (originList, destinationList, count) => {
  const waypoints = [];

  // Flatten the arrays and find matching skyId items
  const originFlights = originList.flat();
  const destinationFlights = destinationList.flat();

  // Loop through originFlights and destinationFlights
  for (const originFlight of originFlights) {
    for (const destinationFlight of destinationFlights) {
      if (originFlight.destination === destinationFlight.origin) {
        waypoints.push({
          'originFlight': originFlight,
          'destinationFlight': destinationFlight,
          'waypoint': {
            'route': `${originFlight.origin}-${originFlight.destination}-${destinationFlight.destination}`,
            'price': parseFloat(originFlight.price) + parseFloat(destinationFlight.price)
          },
        })
      }
    }
  }

  return waypoints;
}



export const getWaypoints2 = async (originList, middleList1, destinationList) => {
  const waypoints = [];

  // Flatten the arrays to make them easier to work with
  const originFlights = originList.flat();
  const middleFlights = middleList1.flat();
  const destinationFlights = destinationList.flat();

  // Filter middleFlights to include only connecting flights
  const connectingMiddleFlights = middleFlights.filter(middleFlight => {
    for (const destinationFlight of destinationFlights) {
      if (
        middleFlight.destination === destinationFlight.origin &&
        originFlights.some(originFlight => originFlight.destination === middleFlight.origin)
      ) {
        return true;
      }
    }
    return false;
  });

  // Loop through originFlights, connectingMiddleFlights, and destinationFlights
  for (const originFlight of originFlights) {
    for (const middleFlight of connectingMiddleFlights) {
      for (const destinationFlight of destinationFlights) {
        // Check if there's a valid flight path
        if (
          originFlight.destination === middleFlight.origin &&
          middleFlight.destination === destinationFlight.origin
        ) {
          waypoints.push({
            'originFlight': originFlight,
            'middleFlight1': middleFlight,
            'destinationFlight': destinationFlight,
            'waypoint': {
              'route': `${originFlight.origin}-${originFlight.destination}-${middleFlight.destination}-${destinationFlight.destination}`,
              'price':
                parseFloat(originFlight.price) +
                parseFloat(middleFlight.price) +
                parseFloat(destinationFlight.price),
            },
          });
        }
      }
    }
  }

  return waypoints;
};

export const getWaypoints3 = async (originList, middleList1, middleList2, destinationList, days) => {
  const waypoints = [];

  // Flatten the arrays to make them easier to work with
  const originFlights = originList.flat();
  const middleFlights1 = middleList1.flat();
  const middleFlights2 = middleList2.flat();
  const destinationFlights = destinationList.flat();

  const limit = 20;

  // Create a map to store connecting flights for faster lookup
  const connectingFlightsMap1 = {};
  const connectingFlightsMap2 = {};

  for (const middleFlight1 of middleFlights1) {
    if (!connectingFlightsMap1[middleFlight1.origin]) {
      connectingFlightsMap1[middleFlight1.origin] = [];
    }
    connectingFlightsMap1[middleFlight1.origin].push(middleFlight1);
  }

  for (const middleFlight2 of middleFlights2) {
    if (!connectingFlightsMap2[middleFlight2.origin]) {
      connectingFlightsMap2[middleFlight2.origin] = [];
    }
    connectingFlightsMap2[middleFlight2.origin].push(middleFlight2);
  }

  let originCounter = 0;
  for (const originFlight of originFlights) {
    if (connectingFlightsMap1[originFlight.destination]) {
      let middle1Counter = 0;
      for (const middleFlight1 of connectingFlightsMap1[originFlight.destination]) {
        if (new Date(originFlight.date) < new Date(middleFlight1.date)) {
          if (connectingFlightsMap2[middleFlight1.destination]) {
            let middle2Counter = 0;
            for (const middleFlight2 of connectingFlightsMap2[middleFlight1.destination]) {
              if (new Date(middleFlight1.date) < new Date(middleFlight2.date)) {
                let destinationCounter = 0;
                for (const destinationFlight of destinationFlights) {
                  if (middleFlight2.destination === destinationFlight.origin && new Date(middleFlight2.date) < new Date(destinationFlight.date)) {
                    waypoints.push({
                      'originFlight': originFlight,
                      'middleFlight1': middleFlight1,
                      'middleFlight2': middleFlight2,
                      'destinationFlight': destinationFlight,
                      'waypoint': {
                        'route': `${originFlight.origin}-${originFlight.destination}-${middleFlight1.destination}-${middleFlight2.destination}-${destinationFlight.destination}`,
                        'price':
                          parseFloat(originFlight.price) +
                          parseFloat(middleFlight1.price) +
                          parseFloat(middleFlight2.price) +
                          parseFloat(destinationFlight.price),
                      },
                    });

                    destinationCounter++;
                    if (destinationCounter >= limit) {
                      break;
                    }
                  }
                }
              }
              middle2Counter++;
              if (middle2Counter >= limit) {
                break;
              }
            }
          }
        }
        middle1Counter++;
        if (middle1Counter >= limit) {
          break;
        }
      }
    }
    originCounter++;
    if (originCounter >= limit) {
      break;
    }
  }

  return waypoints;
};


export const formatDirectToWaypoints = async (directList) => {
  const waypoints = [];

  const originFlights = directList;
  // Loop through originFlights and destinationFlights
  for (const originFlight of originFlights) {

    waypoints.push({
      'direct': true,
      'originFlight': originFlight,
      'destinationFlight': originFlight,
      'waypoint': {
        'route': `${originFlight.origin}-${originFlight.destination}`,
        'price': parseFloat(originFlight.price)
      },
    })
  }

  return waypoints;
}

export const filterOutBadTimes3 = async (waypoints, minStayDurationHours) => {
  // Use Promise.all to calculate stay durations concurrently
  const filteredWaypoints = await Promise.all(waypoints.map(async (waypoint) => {
    const { originFlight, middleFlight1, middleFlight2, destinationFlight } = waypoint;

    // Check if all required properties are defined
    if (
      originFlight?.departureTime === undefined ||
      middleFlight1?.departureTime === undefined ||
      middleFlight2?.departureTime === undefined ||
      destinationFlight?.departureTime === undefined ||
      originFlight?.arrivalTime === undefined ||
      middleFlight1?.arrivalTime === undefined ||
      middleFlight2?.arrivalTime === undefined ||
      destinationFlight?.arrivalTime === undefined
    ) {
      // If any times are undefined, return the waypoint as is
      return waypoint;
    }

    // Calculate the stay duration at each middle location
    const stayDuration1 = await getTimeDifferenceInHours(originFlight.arrivalTime, middleFlight1.departureTime, originFlight.date, middleFlight1.date);
    const stayDuration2 = await getTimeDifferenceInHours(middleFlight1.arrivalTime, middleFlight2.departureTime, middleFlight1.date, middleFlight2.date);
    const stayDuration3 = await getTimeDifferenceInHours(middleFlight2.arrivalTime, destinationFlight.departureTime, middleFlight2.date, destinationFlight.date);

    // Add the stay duration information to the waypoint for reference
    waypoint.stayDuration1 = stayDuration1;
    waypoint.stayDuration2 = stayDuration2;
    waypoint.stayDuration3 = stayDuration3;

    return waypoint;
  }));

  return filteredWaypoints;
};


export const getTimeDifferenceInHours = async (startTime, endTime, startDate, endDate) => {
  const start = new Date(`${startDate} ${startTime}`);
  const end = new Date(`${endDate} ${endTime}`);
  const timeDifference = (end - start) / (1000 * 60 * 60); // Convert milliseconds to hours
  return timeDifference;
};


export const getWaypointResults = async (db, origin, destination, date, days, count, exclude, quality) => {

  // Generate a unique key based on function arguments
  const cacheKey = generateCacheKey('getWaypointResults', origin, destination, date, days, count, exclude, quality);


  // Check if cached result exists
  const cachedResult = await getFromCache(cacheKey);

  if (cachedResult) {
    return cachedResult;
  }

  const twoWeeksAgo = formatISO(add(new Date(), { weeks: -2 }));
  const isWithinTwoWeeks = differenceInDays(parseISO(date), new Date()) < 14;

  count = parseInt(count)
  let results = [];
  let originList;
  let destinationList;
  let waypoints = [];
  let priceLimit = 999;

  let directList = await db.flight.findMany({
    where: {
      AND: [
        { origin: origin },
        { destination: destination },
        { date: date },
        (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
      ],
    },
    orderBy: [
      {
        price: 'asc',
      },
    ],
  });



  let directWaypoints = await formatDirectToWaypoints(directList);

  if (directWaypoints.length) {
    priceLimit = directWaypoints[0].price
  }

  if (origin && destination && date && days && count) {

    // If 1 waypoint
    if (count === 1) {

      let date2 = formatISO(
        add(parseISO(date), {
          days: parseInt(days),
        })
      );
      let dateParts = date2.split("T");
      date2 = dateParts[0];

      if (exclude) {
        originList = await db.flight.findMany({
          where: {
            AND: [
              { origin: origin },
              { date: date },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                NOT: {
                  destination: {
                    in: exclude.split(','),
                  },
                },
              },
              {
                price: {
                  lt: priceLimit,
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }
      else {
        originList = await db.flight.findMany({
          where: {
            AND: [
              { origin: origin },
              { date: date },

              {
                price: {
                  lt: priceLimit,
                },
              },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }

      if (exclude) {
        destinationList = await db.flight.findMany({
          where: {
            AND: [
              { destination: destination },
              { date: date2 },
              {
                price: {
                  lt: priceLimit,
                },
              },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                NOT: {
                  origin: {
                    in: exclude.split(','),
                  },
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }
      else {
        destinationList = await db.flight.findMany({
          where: {
            AND: [
              { destination: destination },
              { date: date2 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                price: {
                  lt: priceLimit,
                },
              },
            ],
          },
        });
      }

      waypoints = await getWaypoints(originList, destinationList);
    }

    // If 2 waypoints
    if (count === 2) {

      let date2 = formatISO(
        add(parseISO(date), {
          days: parseInt(days),
        })
      );
      let dateParts2 = date2.split("T");
      date2 = dateParts2[0];

      let date3 = formatISO(
        add(parseISO(date), {
          days: parseInt(days * 2),
        })
      );
      let dateParts3 = date3.split("T");
      date3 = dateParts3[0];

      if (exclude) {
        originList = await db.flight.findMany({
          where: {
            AND: [
              { origin: origin },
              { date: date },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                price: {
                  lt: priceLimit,
                },
              },
              {
                NOT: {
                  destination: {
                    in: exclude.split(','),
                  },
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }
      else {
        originList = await db.flight.findMany({
          where: {
            AND: [
              { origin: origin },
              { date: date },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
            ],
          },

          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }

      if (exclude) {
        middleList1 = await db.flight.findMany({
          where: {
            AND: [
              { date: date2 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                price: {
                  lt: priceLimit,
                },
              },
              {
                NOT: {
                  origin: {
                    in: exclude.split(','),
                  },
                },
              },
              {
                NOT: {
                  destination: {
                    in: exclude.split(','),
                  },
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }
      else {
        middleList1 = await db.flight.findMany({
          where: {
            AND: [
              { date: date2 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                price: {
                  lt: priceLimit,
                },
              },
            ],
          },
        });
      }

      if (exclude) {
        destinationList = await db.flight.findMany({
          where: {
            AND: [
              { destination: destination },
              { date: date3 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                price: {
                  lt: priceLimit,
                },
              },
              {
                NOT: {
                  origin: {
                    in: exclude.split(','),
                  },
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }
      else {
        destinationList = await db.flight.findMany({
          where: {
            AND: [
              { destination: destination },
              { date: date3 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                price: {
                  lt: priceLimit,
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }

      waypoints = await getWaypoints2(originList, middleList1, destinationList);
    }






    // If 3 waypoints
    if (count === 3) {

      let date2 = formatISO(
        add(parseISO(date), {
          days: parseInt(days),
        })
      );
      let dateParts2 = date2.split("T");
      date2 = dateParts2[0];

      let date3 = formatISO(
        add(parseISO(date), {
          days: parseInt(days * 2),
        })
      );
      let dateParts3 = date3.split("T");
      date3 = dateParts3[0];

      let date4 = formatISO(
        add(parseISO(date), {
          days: parseInt(days * 3),
        })
      );
      let dateParts4 = date4.split("T");
      date4 = dateParts4[0];



      if (exclude) {
        originList = await db.flight.findMany({
          where: {
            AND: [
              { origin: origin },
              { date: date },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                NOT: {
                  destination: {
                    in: exclude.split(','),
                  },
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }
      else {
        originList = await db.flight.findMany({
          where: {
            AND: [
              { origin: origin },
              { date: date },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }

      if (exclude) {
        middleList1 = await db.flight.findMany({
          where: {
            AND: [
              { date: date2 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                NOT: {
                  origin: {
                    in: exclude.split(','),
                  },
                },
              },
              {
                NOT: {
                  destination: {
                    in: exclude.split(','),
                  },
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }
      else {
        middleList1 = await db.flight.findMany({
          where: {
            AND: [
              { date: date2 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
            ],

          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }

      if (exclude) {
        middleList2 = await db.flight.findMany({
          where: {
            AND: [
              { date: date3 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                NOT: {
                  origin: {
                    in: exclude.split(','),
                  },
                },
              },
              {
                NOT: {
                  destination: {
                    in: exclude.split(','),
                  },
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }
      else {
        middleList2 = await db.flight.findMany({
          where: {
            AND: [
              { date: date3 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
            ],
          },
        });
      }

      if (exclude) {
        destinationList = await db.flight.findMany({
          where: {
            AND: [
              { destination: destination },
              { date: date4 },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              {
                NOT: {
                  origin: {
                    in: exclude.split(','),
                  },
                },
              },
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }
      else {
        destinationList = await db.flight.findMany({
          where: {
            AND: [
              { destination: destination },
              (isWithinTwoWeeks ? { createdAt: { gte: twoWeeksAgo } } : null),
              { date: date4 }
            ],
          },
          orderBy: [
            {
              price: 'asc',
            },
          ],
        });
      }

      waypoints = await getWaypoints3(originList, middleList1, middleList2, destinationList);


      if (quality === 'on') {
        waypoints = await filterOutLoops(waypoints);
        waypoints = await filterOutBadTimes3(waypoints, 16);
      }
    }



    let waypointsTotal = await getFromCache('waypointsTotal');

    if (typeof parseInt(waypointsTotal) !== "number" && waypoints.length) {
      await setToCache("waypointsTotal", 0 + parseInt(waypoints.length));

      console.log("waypointsTotal1", waypoints);
      console.log("waypointsTotal1", parseInt(waypoints.length));
    }
    else {
      await setToCache("waypointsTotal", parseInt(waypointsTotal) + parseInt(waypoints.length) + parseInt(waypoints.length));

      console.log("waypointsTotal1", parseInt(waypointsTotal));
      console.log("waypointsTotal1", parseInt(waypoints.length));
    }


    results = [...directWaypoints, ...waypoints];



    results.sort((a, b) => {
      const priceA = a.waypoint.price;
      const priceB = b.waypoint.price;

      if (priceA < priceB) {
        return -1;
      }
      if (priceA > priceB) {
        return 1;
      }
      return 0;
    });



  } else {
    results = [];
  }
  await setToCache(cacheKey, results);


  return results;
}

// Helper function to generate cache key
export function generateCacheKey(prefix, origin, destination, date, days, count, page, exclude, quality) {
  const keyString = `${origin}_${destination}_${date}_${days}_${count}_${page}_${exclude}_${quality}`;
  return `${prefix}_${crypto.createHash('md5').update(keyString).digest('hex')}`;
}



// Helper function to get data from Redis cache
export const getFromCache = async function getFromCache(key) {
  const cachedResult = await redisClient.get(key);
  return cachedResult ? JSON.parse(cachedResult) : null;
}

// Helper function to set data in Redis cache
export const setToCache = async function setToCache(key, data) {
  await redisClient.set(key, JSON.stringify(data));
  // Optionally set expiration time if needed
  // await redisClient.expire(key, expirationInSeconds);
}
